@mixin side($prop) {
  #{$prop}: $side-m;

  @media screen and (min-width: $br-t) {
    #{$prop}: $side-t;
  }
  @media screen and (min-width: $br-l) {
    #{$prop}: $side;
  }
}

.button {
  $root: &;
  position: relative;
  background-repeat: no-repeat;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  overflow: hidden;
  border-radius: 9px;
  box-shadow: $box-shadow-1;

  &:hover {
    // filter: brightness(0.9);
  }

  // &:active {
  //   filter: brightness(0.8);
  // }

  &:after {
    content: '';
    position: absolute;
    @include pos(13px);
    background-size: 100% 100%;
    overflow: hidden;
    border-radius: 6px;
  }

  &--red {
    &:after {
      background-image: url('@/assets/images/buttons/button-large-red-bg.jpg');
    }

    &:active {
      &:after {
        background-image: url('@/assets/images/buttons/button-large-red-pressed-bg.jpg');
      }
    }
  }

  &--blue {
    &:after {
      background-image: url('@/assets/images/buttons/button-large-blue-bg.jpg');
    }

    &:active {
      &:after {
        background-image: url('@/assets/images/buttons/button-large-blue-pressed-bg.jpg');
      }
    }
  }

  &--brown {
    &:after {
      background-image: url('@/assets/images/buttons/button-large-brown-bg.jpg');
    }

    &:active {
      &:after {
        background-image: url('@/assets/images/buttons/button-large-brown-pressed-bg.jpg');
      }
    }
  }

  &--large {
    @include size(267px, 82px);
    background-image: url('@/assets/images/buttons/button-large-border.png');

    &:after {
      @include size(239px, 57px);
    }

    // @media screen and (max-width: $br-768) {
    //   @include size(230px, 71px);
    //   background-image: url('@/assets/images/buttons/button-large-border.png?size=230');

    //   &:after {
    //     @include size(207px, 49px);
    //   }
    // }
  }

  &--medium {
    @include size(252px, 70px);
    background-image: url('@/assets/images/buttons/button-medium-border.png');

    &:after {
      @include size(224px, 44px);
    }
  }

  &--disabled {
    cursor: not-allowed;

    &:after {
      filter: grayscale(80%);
    }
  }

  &__content {
    flex: 0 1 auto;
    text-shadow: $text-shadow-1;
    position: relative;
    z-index: 4;
    display: block;
    margin-top: -0.15em;
    font-weight: 600;

    #{$root}:active & {
      transform: translateY(1px);
    }
  }

  &__sub {
    flex: 0 1 auto;
    text-shadow: $text-shadow-1;
    position: relative;
    z-index: 4;
    display: block;
    letter-spacing: -0.05em;
    opacity: 0.6;
    margin-top: -0.23em;
    margin-bottom: -0.13em;
    font-weight: 400;

    #{$root}:active & {
      transform: translateY(1px);
    }
  }
}

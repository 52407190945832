.menu {
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 100px;
  text-align: center;

  @media screen and (max-width: $br-1300) {
    padding-bottom: 0;
  }

  &__wrap {
    position: relative;
  }

  &__logo {
    margin-bottom: 50px;
    text-align: center;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  &__items {
  }

  &__button {
    margin-top: 12px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__click {
    pointer-events: none;
    position: absolute;
    bottom: -70px;
    left: 0;
    width: 100%;

    // $time: 1.5s;
    // $scale: 0.95;

    // @keyframes bounce {
    //   100% {
    //     transform: scale3d($scale, $scale, $scale);
    //     opacity: 0.7;
    //   }
    // }

    // animation: bounce $time ease-in alternate infinite;
  }
}

/* Colors
---------------------------- */

$c-white: #ffffff;
$c-milk: #dedede;
$c-black: #000000;
$c-dark: #1e1d1d;
$c-shade: #29292a;
$c-stone: #353535;
$c-brown: #413622;
$c-ash: #54524d;
$c-metal: #b8b8b8;

$c-red: #932518;
$c-blood: #f30000;
$c-green: #6b9318;
$c-green-10: lighten($c-green, 10%);
$c-facebook: #385bab;

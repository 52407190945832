.panel {
  $root: &;
  position: relative;
  background-image: url('@/assets/images/panel/panel-bg-seamless.jpg');
  background-repeat: repeat;
  background-position: center;
  // @include size(1345px, 642px);
  box-shadow: $box-shadow-2;

  &__close {
    position: absolute;
    top: -15px;
    right: -15px;
    z-index: 5;

    @media screen and (max-width: $br-768) {
      top: -13px;
      right: -8px;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    padding: 20px 40px 35px;

    @media screen and (max-width: $br-768) {
      padding: 15px 25px 20px;
    }
  }

  &__borders {
    position: absolute;
    @include size(100%);
    @include pos(0);
    z-index: 1;
  }

  &__border {
    position: absolute;
    overflow: hidden;

    &--left {
      top: 0px;
      left: -5px;
      @include size(14px, 100%);
      background-image: url('@/assets/images/panel/panel-border-left.png');
      background-repeat: repeat-y;
      background-size: 14px auto;
      border-radius: 10px 0 0 10px;
    }

    &--right {
      top: 0px;
      right: -5px;
      @include size(14px, 100%);
      background-image: url('@/assets/images/panel/panel-border-right.png');
      background-repeat: repeat-y;
      background-size: 14px auto;
      border-radius: 0 10px 10px 0;
    }

    &--top {
      left: 0px;
      top: -5px;
      @include size(100%, 10px);
      background-image: url('@/assets/images/panel/panel-border-top.png');
      background-repeat: repeat-x;
      background-size: auto 10px;
      border-radius: 10px 10px 0 0;
    }

    &--bottom {
      left: 0px;
      bottom: -5px;
      @include size(100%, 16px);
      background-image: url('@/assets/images/panel/panel-border-bottom.png');
      background-repeat: repeat-x;
      background-size: auto 16px;
      border-radius: 10px;
    }
  }
}

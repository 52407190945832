/* Placeholder mixin
  -------------------------------------------------- */

/*  
  Placeholder (inputs / textarea):
  Overwrites default placeholder.
*/
@mixin placeholder {
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

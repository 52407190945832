@mixin pos($left: '', $top: $left, $right: '', $bottom: ''){
    @if ($left != '') { 
        left: $left;
    }
    @if ($top != '') { 
        top: $top;
    }
    @if ($right != '') { 
        right: $right;
    }
    @if ($bottom != '') { 
        bottom: $bottom;
    }
}

@mixin posD {
    position: absolute;
    @include pos(0);
    width: 100%;
    height: 100%;
}
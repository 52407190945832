.button-round {
  $root: &;
  position: relative;
  background-repeat: no-repeat;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  overflow: hidden;
  border-radius: 100%;
  box-shadow: $box-shadow-1;
  @include size(36px, 37px);
  background-image: url('@/assets/images/round-button/round-button-bg.png');

  &:active {
    background-image: url('@/assets/images/round-button/round-button-bg-pressed.png');
  }

  &__content {
    #{$root}:active & {
      transform: translateY(1px);
    }
  }
}

.button-group {
  display: grid;
  grid-gap: 12px;
  justify-content: center;

  margin-top: 30px;

  &:first-child {
    margin-top: 0;
  }
}

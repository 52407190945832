.account {
  max-width: 370px;
  width: 100%;

  &__tabs {
    margin-bottom: 22px;
  }

  &__head {
    display: flex;
    align-items: flex-start;
  }

  &__avatar {
    background-color: $c-dark;
    @include size(92px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
  }

  &__avatar-icon {
    @include size(51px, 62px);

    svg {
      fill: $c-shade;
    }
  }

  &__table {
    td {
      padding: 8px 0;

      &:first-child {
        padding-right: 24px;
        color: $c-milk;
      }

      &:last-child {
        font-weight: 600;
      }
    }
  }

  &__field-td {
    padding: 0 !important;
  }

  &__username {
    margin-left: -13px;
    width: 150px;
  }

  &__footer {
    margin-top: 40px;
  }

  &__buttons {
    text-align: center;
    margin-top: 30px;
  }
}

* {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background: none;
  cursor: pointer;
  line-height: 1;
}

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  -webkit-appearance: none;
}

input {
  border: 0;
  outline: 0;
}

button {
  border: 0;
  outline: 0;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: no-repeat right center / 1em;
  border-radius: 0;
}

::-ms-expand {
  display: none;
}

:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.54);
}

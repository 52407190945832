.nav {
  $root: &;

  position: fixed;
  z-index: $z-nav;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  display: grid;
  align-content: start;
  box-sizing: border-box;
  pointer-events: none;
  padding: 40px $side;
  @include side('row-gap');
  grid-template-columns: auto 1fr auto;

  &--desktop {
    grid-template-rows: auto;
    grid-template-areas:
      'menu . .'
      'account . .'
      'leaderboard . .'
      '. . .';
  }

  &--landscape {
    padding: 4vh 0;
    justify-items: center;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'menu . logo'
      '. . .'
      'account . leaderboard';
  }

  &--portrait {
    @include side('padding-left');
    @include side('padding-right');
    @include side('padding-top');
    padding-bottom: 10px;
    justify-items: center;
    align-items: start;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'menu . logo'
      '. . .'
      'account leaderboard . ';

    &#{$root}--menuState {
      &:after {
        display: block;
      }
    }
  }

  &__button {
    position: relative;
    z-index: 2;
    pointer-events: auto;
    text-align: center;

    &--account {
      grid-area: account;
    }

    &--leaderboard {
      grid-area: leaderboard;
    }

    &--menu {
      grid-area: menu;
    }
  }

  &__logo {
    pointer-events: auto;
    grid-area: logo;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      flex: 0 0 auto;
    }
  }
}

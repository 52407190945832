/*  
  Autofill:
  Overwrites default input autofill bg color
  ---------------------------------------------- */
  
@mixin autofill($color, $bg-color) {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $bg-color inset;
    -webkit-text-fill-color: $color;
    transition: background-color 5000s ease-in-out 0s;
  }
}
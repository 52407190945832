.login {
  width: 350px;

  @media screen and (max-width: $br-768) {
    width: 100%;
  }

  &__tabs {
    margin-bottom: 22px;
  }
}

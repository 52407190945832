.connect {
  width: 340px;
  text-align: center;

  @media screen and (max-width: $br-768) {
    width: 100%;
  }

  &__h {
    margin-bottom: 15px;
  }
}

@mixin typography-xl {
  @include font(20px, 1.2, -0.05em, 600);
}

@mixin typography-l {
  @include font(18px, 1.2, 0em, 600);
}

@mixin typography-m {
  @include font(16px, 1.2);
}

@mixin typography-s {
  @include font(14px, 1.2, 0em, 600);
}

@mixin typography-xs {
  @include font(12px, 1.2, 0.025em);
}

@mixin typography-social {
  @include font(11px, 1.2, 0.07em, 700, uppercase);
  font-family: $font-openSans;
}

@mixin typography-th {
  @include font(14px, 1.2, -0.05em, 600);
}

@mixin typography-bevel {
  @include font(36px, 1.2, 0.04em, 400);
  text-shadow: -1px 1px 0px $c-metal, -2px 0px 0px $c-metal, $text-shadow-1, $text-shadow-2;
}

.typography {
  &--xl {
    @include typography-xl;
  }

  &--l {
    @include typography-l;
  }

  &--m {
    @include typography-m;
  }

  &--s {
    @include typography-s;
  }

  &--xs {
    @include typography-xs;
  }

  &--social {
    @include typography-social;
  }

  &--th {
    @include typography-th;
  }

  &--bevel {
    @include typography-bevel;
  }
}

.endgame-flag {
  position: relative;
  @include size(196px, 235px); // Need to match image size inside

  &__gem-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__gem {
    flex: 0 0 auto;
  }

  &__flag {
  }

  &__content {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
  }
}

.profile-screen {
  // max-width: 400px;

  &__title {
    text-align: center;
    margin-bottom: 24px;
  }

  &__top {
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: $br-480) {
      display: block;
    }
  }

  &__avatar {
    flex: 0 0 auto;
    background-color: $c-dark;
    @include size(92px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;

    @media screen and (max-width: $br-480) {
      margin-right: 0;
      margin-bottom: 24px;
    }
  }

  &__avatar-icon {
    @include size(51px, 62px);

    svg {
      fill: $c-shade;
    }
  }

  &__flag {
    vertical-align: middle;
    margin-right: 4px;
    display: inline-block;
  }

  &__table {
    @media screen and (max-width: $br-480) {
      width: 100%;
    }

    td {
      padding: 8px 0;
      vertical-align: top;

      &:first-child {
        padding-right: 18px;
        color: $c-metal;
        width: 80px;
      }

      &:last-child {
        font-weight: 600;
      }
    }
  }

  &__buttons {
    text-align: center;
    margin-top: 30px;
  }
}

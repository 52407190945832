/* Size mixin
  -------------------------------------------------- */

/*  
  Just a quick size:
  @include size(100%);
  @include size(22px, 20px);
*/
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

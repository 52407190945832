.game-interface {
  $root: &;
  position: fixed;
  z-index: $z-game-interface;
  // background-color: rgba(green, 0.2);
  pointer-events: none;
  filter: blur(2px);

  &--active {
    filter: none;
  }

  display: grid;
  box-sizing: border-box;
  align-content: center;

  @include desktop {
    padding: 110px 0 40px 0;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: minmax(200px, 400px);
    grid-template-areas: 'side1 . side2';
  }

  @include landscape {
    padding: 110px 0 4vh 0;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: minmax(200px, 400px);
    grid-template-areas: 'side1 . side2';
  }

  @include portrait {
    grid-template-rows: auto 1fr auto;
    grid-gap: 30px;
    @include side('padding-left');
    @include side('padding-right');
    grid-template-areas:
      'side1'
      '. '
      'side2';
  }

  &__side {
    box-sizing: border-box;
    pointer-events: auto;
    max-height: 500px;

    &--1 {
      grid-area: side1;
    }

    &--2 {
      grid-area: side2;
    }

    @include desktop {
      &--1 {
        padding: 0 10px 0 29px;
      }

      &--2 {
        padding: 0 29px 0 10px;
        display: grid;
        grid-gap: 30px;
        grid-template-rows: 1fr auto;
      }
    }

    @include landscape {
      &--2 {
        display: grid;
        grid-gap: 20px;
        grid-template-rows: 1fr auto;
      }
    }

    @include portrait {
      display: flex;
      align-items: center;

      &--2 {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: 1fr auto;
      }
    }
  }

  &__timer {
    #{$root}--desktop &,
    #{$root}--landscape & {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: auto;
      height: 0;
    }

    #{$root}--portrait & {
      margin-left: 20px;
      flex: 0 1 auto;
      width: 120px;
      text-align: right;
    }
  }

  &__action {
    pointer-events: auto;
    text-align: center;
    box-sizing: border-box;

    @include portrait {
      @include side('padding-bottom');
      align-self: end;
    }
  }
}

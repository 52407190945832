.centered {
  $root: &;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  @include side('padding-left');
  @include side('padding-right');
  overflow-y: auto;
  overflow-x: hidden;

  &__wrap {
    flex: 0 0 auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    #{$root}--portrait & {
      padding-top: 120px;
      padding-bottom: 145px;

      @media screen and (max-width: $br-768) {
        padding-top: 80px;
        padding-bottom: 115px;
      }
    }

    #{$root}--landscape & {
      @include side('padding-top');
      @include side('padding-bottom');
    }
    // min-height: auto;

    @media screen and (min-width: $br-t + 1) {
      @include side('padding-top');
      @include side('padding-bottom');
    }
  }

  &__content {
    margin-top: auto;
    margin-bottom: auto;

    @media screen and (max-width: $br-t) {
      // margin: 0 auto;
    }
  }
}

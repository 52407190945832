.input {
  $root: &;
  background-color: $c-dark;
  border-radius: 2px;
  position: relative;

  &--m {
    @include typography-m;
  }

  &--s {
    @include typography-s;
  }

  &--xs {
    @include typography-xs;
  }

  &--error {
    outline: 1px solid rgba($c-red, 0.9);
  }

  &__input {
    padding: 0 13px;
    height: 40px;
    min-width: 0;
    color: currentColor;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: inherit;

    @include placeholder {
      color: $c-milk;
    }

    @include autofill($c-milk, $c-dark);

    #{$root}--suffix & {
      padding-right: 40px;
    }
  }

  &__suffix {
    position: absolute;
    top: 10px;
    @include size(20px);
    right: 10px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__clear {
    @include size(10px);
    cursor: pointer;
    padding: 5px;
  }
}

.flag {
  &::after {
    content: '';
    display: block;
    padding-bottom: 119.8%;
  }
  
  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
  }
}
.social-button {
  $root: &;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 28px;
  cursor: pointer;
  border-radius: 2px;
  color: $c-white;

  &:before {
    content: '';
    flex: 0 0 auto;
    display: block;
    @include size(18px);
    margin-right: 6px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &--facebook {
    background-color: $c-facebook;

    &:before {
      background-image: url('@/assets/images/social/icon-facebook.svg');
    }
  }

  &--google {
    background-color: $c-white;
    color: $c-dark;

    &:before {
      margin-right: 10px;
      background-image: url('@/assets/images/social/icon-google.svg');
    }
  }

  &__content {
    flex: 0 1 auto;
    margin-top: -1px;
  }
}

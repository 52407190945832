.login-form {
  &__social {
  }

  &__social-btn {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__forgot {
    text-align: center;
    margin-top: 22px;
  }
}

.select {
  $root: &;
  background-color: $c-dark;
  border-radius: 2px;
  position: relative;

  &--m {
    @include typography-m;
  }

  &--s {
    @include typography-s;
  }

  &--xs {
    @include typography-xs;
  }

  &--error {
    outline: 1px solid rgba($c-red, 0.9);
  }

  &__select {
    padding: 0 40px 0 13px;
    height: 40px;
    min-width: 0;
    border: 0;
    color: currentColor;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: inherit;
    outline: none;
    cursor: pointer;

    @include placeholder {
      color: $c-milk;
    }

    @include autofill($c-milk, $c-dark);
  }

  &__chevron {
    position: absolute;
    top: 0;
    @include size(40px, 100%);
    right: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }
}

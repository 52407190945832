/* z-index
  -------------------------------------------------- */

$z-modal: 1300;
$z-nav: 150;
$z-leaderboard: 150;
$z-ingame-modal: 140;
$z-page: 100;
$z-overlay: 75;
$z-game-interface: 50;
$z-game: 1;

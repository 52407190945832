.game-modal {
  width: 340px;
  text-align: center;

  @media screen and (max-width: $br-768) {
    width: 100%;
  }

  &__wrap {
  }

  &__h {
  }

  &__share {
    margin-top: 8px;
    cursor: pointer;
  }

  &__copy {
    @include size(15px);
  }

  &__players {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin-top: 12px;
  }

  &__player {
    // flex: 0 0 auto;
  }

  &__vs {
    // flex: 0 0 auto;
    margin: 38px auto 0;

    @media screen and (max-width: $br-768) {
      margin-top: 20px;
    }
  }
}

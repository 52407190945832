html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
  background: transparent;
}

.confirm-exit {
  width: 330px;
  text-align: center;

  &__wrap {
  }

  &__h {
  }

  &__text {
    margin-top: 8px;
    color: $c-milk;
  }

  &__buttons {
    margin-top: 20px;
  }
}

.modal {
  $root: &;
  position: fixed;
  z-index: $z-modal;
  inset: 0px;
  @include side('padding');
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--portrait {
    &#{$root}--padding-small {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &#{$root}--padding-default {
      padding-top: 115px;
      padding-bottom: 115px;
    }

    // @media screen and (max-width: $br-768) {
    //   padding-top: 115px;
    //   padding-bottom: 115px;
    // }
  }

  &--landscape {
    @include side('padding-top');
    @include side('padding-bottom');
  }

  &--inGame {
    z-index: $z-ingame-modal;
  }

  &--active {
    opacity: 1;
  }

  &__content {
    outline: none;
    margin-top: auto;
    margin-bottom: auto;

    #{$root}--portrait:not(#{$root}--isCentered) & {
      margin-top: 0;
    }
  }

  &__backdrop {
    z-index: -1;
    position: fixed;
    background-color: rgba($c-black, 0.6);
    inset: 0px;
    -webkit-tap-highlight-color: transparent;
    pointer-events: none;
  }
}

/* Breakpoints
  -------------------------------------------------- */

$br-320: 320px;
$br-360: 360px; // Common 41% usage 2018
$br-375: 375px;
$br-420: 420px;
$br-480: 480px;
$br-600: 600px;
$br-680: 680px;
$br-768: 768px;
$br-800: 800px;
$br-820: 820px;
$br-1024: 1024px;
$br-1200: 1200px;
$br-1300: 1300px;
$br-1440: 1440px;
$br-1600: 1600px;
$br-1920: 1920px;
$br-2200: 2200px;
$br-2440: 2440px;
$br-2560: 2560px;

$br-m: $br-375;
$br-t: $br-768;
$br-l: $br-1024;
$br-d: $br-1300;

@mixin desktop {
  @media screen and (min-width: #{$br-l + 1px}) {
    @content;
  }
}

@mixin landscape {
  @media screen and (max-width: $br-l) and (orientation: 'landscape') {
    @content;
  }
}

@mixin portrait {
  @media screen and (max-width: $br-l) and (orientation: 'portrait') {
    @content;
  }
}

/* 
  Example:

  @media screen and (max-width: $br-600) {

  }
*/

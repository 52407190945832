.tab {
  text-align: center;
  padding: 6px 10px;
  position: relative;
  cursor: pointer;
  opacity: 0.5;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  &--selected {
    opacity: 1;

    &:after {
      content: '';
      display: block;
      position: absolute;
      @include size(100%, 1px);
      background-color: $c-milk;
      left: 0;
      bottom: -1px;
    }
  }
}

.square-button {
  $root: &;
  position: relative;
  cursor: pointer;
  text-align: center;

  &__button {
    @include size(72px, 70px);
    background-image: url('@/assets/images/buttons/button-nav-border.png');
    background-repeat: no-repeat;
    overflow: hidden;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $br-768) {
      @include size(56px, 54px);
      background-image: url('@/assets/images/buttons/button-nav-border.png?size=56');
    }

    #{$root}--elevation-0 & {
      box-shadow: $box-shadow-3;
    }

    #{$root}--elevation-5 & {
      box-shadow: $box-shadow-1;
    }

    &:after {
      content: '';
      position: absolute;
      @include pos(13px);
      background-size: 100% 100%;
      overflow: hidden;
      background-repeat: no-repeat;
      border-radius: 6px;
      @include size(44px);
      background-image: url('@/assets/images/buttons/button-nav-brown-bg.jpg');

      @media screen and (max-width: $br-768) {
        @include pos(10px);
        @include size(35px);
        background-image: url('@/assets/images/buttons/button-nav-brown-bg.jpg?size=35');
      }
    }

    #{$root}:active & {
      &:after {
        background-image: url('@/assets/images/buttons/button-nav-brown-pressed-bg.jpg');

        @media screen and (max-width: $br-768) {
          background-image: url('@/assets/images/buttons/button-nav-brown-pressed-bg.jpg?size=35');
        }
      }
    }
  }

  &__icon {
    flex: 0 0 auto;
    position: relative;
    z-index: 4;
    display: block;
    color: $c-white;
    margin-top: -2px;

    #{$root}:active & {
      transform: translateY(1px);
    }
  }

  &__content {
    margin-top: 10px;
    width: 72px;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: bold;

    @media screen and (max-width: $br-768) {
      margin-top: 10px;
      width: 56px;
    }

    @media screen and (max-width: $br-375) {
      margin-top: 6px;
    }
  }
}

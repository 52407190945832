.endgame {
  $root: &;
  text-align: center;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__top {
    margin: 0 auto;
    position: relative;
    width: 196px;
  }

  &__gem-wrap {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @keyframes gem {
      0% {
        opacity: 0;
        transform: translateY(-15px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    }

    #{$root}--hasAnimation & {
      opacity: 0;
      animation: gem 1s ease 0.7s forwards;
    }
  }

  &__gem {
    flex: 0 0 auto;
  }

  &__title {
    position: absolute;
    z-index: 5;
    top: 100px;
    left: 0;
    width: 100%;

    #{$root}--hasAnimation & {
      opacity: 0;
      animation: fadeIn 1s ease forwards;
    }
  }

  &__flag {
    @keyframes flag {
      0% {
        opacity: 0;
        transform: translateY(-30px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    }

    #{$root}--hasAnimation & {
      opacity: 0;
      animation: flag 1s ease 0.7s forwards;
    }
  }

  &__content {
    display: grid;
    grid-template-rows: auto 30px auto;
  }

  &__again {
    #{$root}--hasAnimation & {
      opacity: 0;
      animation: fadeIn 1s ease 1.5s forwards;
    }
  }

  &__buttons {
    max-width: 300px;
    margin-top: 10px;

    #{$root}--hasAnimation & {
      opacity: 0;
      animation: fadeIn 1s ease 1.5s forwards;
    }
  }

  &__score {
    text-shadow: $text-shadow-1;
  }

  &__score-change {
    &--neg {
      color: $c-blood;
    }
    &--pos {
      color: $c-green-10;
    }
  }
}

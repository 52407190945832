@use 'sass:math';

.loading {
  text-align: center;
  margin-right: -2px;

  span {
    @include size(8px);
    border-radius: 50%;
    background-color: $c-white;
    display: inline-block;
    margin: 0 3px;

    $time: 0.6s;
    $scale: 0.5;
    $delay: math.div($time, 3);

    @keyframes loading {
      100% {
        transform: scale3d($scale, $scale, $scale);
      }
    }

    animation: loading $time ease alternate infinite;

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        animation-delay: ($i - 1) * $delay;
      }
    }
  }
}

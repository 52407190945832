.status-message {
  $root: &;
  border-radius: 4px;
  position: relative;
  padding: 10px 14px 10px 40px;
  text-align: left;

  &--error {
    background-color: rgba($c-red, 0.3);
    color: $c-white;
  }

  &--success {
    background-color: rgba($c-green, 0.3);
    color: $c-white;
  }

  &__icon {
    left: 9px;
    top: 7px;
    position: absolute;
    @include size(20px);

    #{$root}--error & {
      svg {
        fill: $c-red;
      }
    }
    #{$root}--success & {
      svg {
        fill: $c-green;
      }
    }
  }
}

.field-group {
  display: block;
  margin-top: 20px;
  // text-align: left;

  &:first-child {
    margin-top: 0;
  }

  & > * {
    margin-top: 8px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__label {
    margin-bottom: 10px;
  }
}

.overlay {
  $root: &;
  position: fixed;
  z-index: $z-overlay;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  pointer-events: none;
  background-color: rgba($c-black, 0.6);
  opacity: 1;

  &--hidden {
    opacity: 0;
  }
}

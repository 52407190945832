.icon {
  $root: &;
  @include size(24px);
  flex: 0 0 auto;

  svg {
    fill: currentColor;
    @include size(100%);
    display: block;
  }

  @media screen and (max-width: $br-768) {
    @include size(20px);
  }
}

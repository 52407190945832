.gem {
  position: relative;

  img {
    display: block;
    pointer-events: none;
    position: relative;
    z-index: 2;
  }

  &__halo {
    $offset: 50;
    position: absolute;
    z-index: 1;
    top: -#{$offset}#{'%'};
    left: -#{$offset}#{'%'};
    @include size(#{100 + $offset * 2}#{'%'});
    background: radial-gradient(
      circle,
      rgba(254, 218, 97, 1) 0%,
      rgba(255, 184, 51, 0.7343312324929971) 26%,
      rgba(255, 128, 23, 0.39539565826330536) 35%,
      rgba(255, 255, 255, 0) 50%
    );
  }
}
